<template>
  <section class="container">
    <h2 class="py-5 text-white">"{{ keyword }}" 的搜索结果</h2>
    <!-- <div class="mb-4">
      <h5 class="text-white pb-4">游戏</h5>
      <div v-if="!loading && !hasGameResult" class="text-secondary text-center p-5">
        抱歉，没有发现关于“{{ keyword }}”的游戏
      </div>
      <div v-if="!loading" class="card-box mt-1">
        <collection-weapon class="card-item" v-for="(item, k) in result" :key="k" :item="item" />
      </div>
      <div v-if="loading" class="card-box">
        <div v-for="i in 4" :key="`skeleton-${i}`" class="card-item">
          <b-skeleton height="420px" style="border-radius:32px" />
        </div>
      </div>
    </div> -->
    <div class="mb-4">
      <h5 class="text-white pb-4">装备</h5>
      <div v-if="!loading && !hasResult" class="text-secondary text-center p-5">
        抱歉，没有发现关于“{{ keyword }}”的装备
      </div>
      <div v-if="!loading" class="card-box mt-1">
        <collection-weapon
          class="card-item"
          v-for="(item, k) in result"
          :key="k"
          :item="item"
          @click="$router.push(`/store/${item.market_hash_name}`)"
        />
      </div>
      <div v-if="loading" class="card-box">
        <div v-for="i in 4" :key="`skeleton-${i}`" class="card-item">
          <b-skeleton height="420px" style="border-radius:32px" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AppService } from '@/services/api'

export default {
  name: 'SearchResult',
  data() {
    return {
      keyword: this.$route.query.keyword,
      result: null,
      gameResult: null,
      loading: false,
    }
  },
  computed: {
    hasResult() {
      return this.result && this.result.length > 0
    },
    hasGameResult() {
      return this.gameResult && this.gameResult.length > 0
    },
  },
  created() {
    this.search()
  },
  watch: {
    $route(v) {
      this.keyword = this.$route.query.keyword
    },
    keyword() {
      this.search()
    },
  },
  methods: {
    search() {
      this.loading = true
      AppService.searchGlobal(this.keyword)
        .then(({ data }) => {
          this.result = data.data.map(i => {
            const rv = i.item
            rv.price = i.price
            return rv
          })
        })
        .catch()
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
